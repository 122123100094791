<template>
  <div class="reg-by-token">
    <h2 class="text__main-title text--centered text--bold append-dot">
      {{ $t('thank_for_reg') }}
    </h2>
    <p class="text--centered">{{ $t('can_login') }}</p>
    <ElButton type="primary" class="reg-by-token__go-to-login" @click="goToLogin">{{
      $t('sign_in')
    }}</ElButton>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class RegisteredByToken extends Vue {
  private goToLogin() {
    this.$router.push({ name: 'login' });
  }
}
</script>

<style lang="scss" scoped>
.reg-by-token {
  text-align: center;
  &__go-to-login {
    margin-top: 32px;
  }
}
</style>
