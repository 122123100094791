import { TabEventEmitterImpl } from './TabEventEmitterImpl';
import { TabEventEmitters } from '../interfaces/TabEventEmitters';

const DASHBOARD_INITIATED = 'dashboardInitiated'; //Fired by dashboard
const LOGIN_PAGE_INITIATED = 'loginPageInitiated'; // Handled by dashboard

export const tabEventEmittersCollection: TabEventEmitters = {
  loginPageInitiated: new TabEventEmitterImpl(LOGIN_PAGE_INITIATED),
  dashboardInitiated: new TabEventEmitterImpl(DASHBOARD_INITIATED),
};
