import { AxiosInstance, AxiosRequestConfig } from 'axios';

/**
 * @class HttpService Base axios http service class
 */
class HttpService {
  private _instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this._instance = instance;
  }

  async post<Response = any>(path: string, data?: object, config?: AxiosRequestConfig) {
    return this._instance.post<Response>(path, data, config);
  }

  async get<Response = any>(path: string, config?: AxiosRequestConfig) {
    return this._instance.get<Response>(path, config);
  }
}

export { HttpService };