import axios from 'axios';

const baseURL = window.location.protocol + '//' + window.location.host;

const BaseAxiosInstance = axios.create({
  baseURL,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
});

export { BaseAxiosInstance };
