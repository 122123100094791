<template>
  <div id="app">
    <ElRow>
      <Header />

      <ElRow clas="section" type="flex" justify="center">
        <RouterView />
      </ElRow>
    </ElRow>

    <Footer />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { EventBusType } from './modules/EventBus/EventBusType';

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class App extends Vue {
  created() {
    this.$bus.on(EventBusType.SESSION_EXPIRED, this.handleSessionExpired);
  }

  handleSessionExpired() {
    const message = this.$t('session_expired') as string;
    this.$message({ message, type: 'error', showClose: true, duration: 5000 });
  }
}
</script>

<style lang="scss" scoped>
#app {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
