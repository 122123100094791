<template>
  <ElTooltip placement="right" manual :value.sync="isVisible">
    <div slot="content">
      <h6 class="password-tooltip__title">{{ $t('password_tooltip_title') }}</h6>
      <ul class="password-tooltip__list">
        <li>{{ $t('password_tooltip_rule_symbols_count') }}</li>
        <li>{{ $t('password_tooltip_rule_symbols_general') }}</li>
        <li>{{ $t('password_tooltip_rule_numbers') }}</li>
        <li>{{ $t('password_tooltip_rule_special_symbols') }}</li>
      </ul>
    </div>

    <slot />
  </ElTooltip>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const PasswordTooltipProps = Vue.extend({
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
});

@Component
export default class PasswordTooltip extends PasswordTooltipProps {}
</script>

<style lang="scss" scoped>
.password-tooltip {
  &__title {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 0;
    margin-top: 0;
  }
  &__list {
    margin-top: 0;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li::before {
      content: '\2022';
      display: inline-block;
      margin-right: 4px;
      font-size: 10px;
    }
  }
}
</style>
