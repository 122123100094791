import i18n from '@/plugins/i18n';

export const rules = {
  email: [
    {
      required: true,
      message: i18n.t('validation_email_require'),
      trigger: 'blur',
    },
    {
      type: 'email',
      required: true,
      message: i18n.t('validation_email'),
      trigger: 'blur',
    },
  ],
};
