<template>
  <div class="password-recovery">
    <el-row>
      <h2 class="text__main-title text--bold append-dot mb-16">{{ $t('enter_new_password') }}</h2>
      <p v-if="backendError" class="text--centered text--error">
        {{ $t('error__server_validation_failure') }}
      </p>
      <ElForm
        class="password-recovery__form"
        :model="form"
        :rules="rules"
        ref="form"
        label-position="top"
        hide-required-asterisk
        @submit.native.prevent="submitForm"
      >
        <ElFormItem prop="password">
          <ElRow slot="label" class="login__password_label" type="flex" justify="space-between">
            <span>{{ $t('password_label') }}</span>
          </ElRow>
          <ElInput
            id="password"
            type="password"
            v-model="form.password"
            :placeholder="$t('password_placeholder')"
            show-password
          />
        </ElFormItem>

        <ElFormItem class="mb-24" prop="confirmPassword">
          <ElRow slot="label" class="login__password_label" type="flex" justify="space-between">
            <span>{{ $t('confirm_password_label') }}</span>
          </ElRow>
          <ElInput
            id="passwordConfirm"
            v-model="form.confirmPassword"
            :placeholder="$t('password_placeholder')"
            show-password
          />
        </ElFormItem>
        <ElFormItem>
          <ElRow type="flex" justify="center">
            <ElButton
              id="submit"
              type="primary"
              class="enter-button"
              native-type="submit"
              :loading="pageIsLoading"
              >{{ $t('change_password') }}</ElButton
            >
          </ElRow>
        </ElFormItem>
      </ElForm>
    </el-row>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
// Helpers
import entranceHttpService from '@/resource/EntranceHttpService';
import i18n from '@/plugins/i18n';
// Types
import { ElForm } from 'element-ui/types/form';

@Component
export default class ChangePassword extends Vue {
  $refs!: {
    form: ElForm;
  };

  private form = {
    token: '',
    password: '',
    confirmPassword: '',
  };

  private pageIsLoading = false;
  private backendError = false;

  // Validation rules
  private validatePassIsEqual = (rule: any, value: string, callback: Function) =>
    value !== this.form.password ? callback(new Error()) : callback();

  private rules = {
    password: [
      {
        required: true,
        message: i18n.t('validation_password'),
        trigger: 'blur',
      },
      {
        range: true,
        min: 6,
        message: i18n.t('validation_password_length', { n: 6 }),
        trigger: 'blur',
      },
    ],
    confirmPassword: [
      {
        validator: this.validatePassIsEqual,
        message: i18n.t('passwords_must_match'),
        trigger: 'blur',
      },
    ],
  };

  async created() {
    const { token } = this.$route.params;
    this.form.token = token;
    await this.getUserByToken(token);
  }

  private processChangePasswordFormError() {
    this.backendError = true;
  }

  async getUserByToken(token: string) {
    try {
      this.pageIsLoading = true;
      return await entranceHttpService.getUserByToken(token);
    } catch (e) {
      this.processChangePasswordFormError();
    } finally {
      this.pageIsLoading = false;
    }
  }

  async changePassword() {
    try {
      const { token, password, confirmPassword } = this.form;
      this.pageIsLoading = true;
      await entranceHttpService.changePassword({
        token,
        password,
        confirm_password: confirmPassword,
      });
    } catch (e) {
      this.processChangePasswordFormError();
    } finally {
      this.pageIsLoading = false;

      this.$router.push({ name: 'login' });
    }
  }

  private submitForm() {
    this.$refs.form.validate(valid => {
      if (!valid) {
        return;
      }
      this.pageIsLoading = true;

      this.changePassword();
    });
  }
}
</script>

<style lang="scss" scoped>
.password-recovery {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 355px;

  &__form {
    width: 320px;
    margin: 0 auto;
  }
}
</style>
