import { AssetsProvider } from '../AssetsProvider';
import { ErrorStatus } from './ErrorStatus';

interface ErrorStatusAssets {
  message: string;
}

export const map: {
  [K in ErrorStatus]: ErrorStatusAssets;
} = {
  [ErrorStatus.EmailNotConfirmed]: {
    message: 'error__email_not_confirmed',
  },
  [ErrorStatus.InvalidCredentials]: {
    message: 'error__invalid_credentials',
  },
  [ErrorStatus.ServerFailure]: {
    message: 'error__server_failure',
  },
  [ErrorStatus.TokenExpired]: {
    message: 'error__token_expired',
  },
  [ErrorStatus.TokenNotFit]: {
    message: 'error__token_not_fit',
  },
  [ErrorStatus.UserAlreadyExists]: {
    message: 'error__user_already_exists',
  },
  [ErrorStatus.Default]: {
    message: 'error__server_validation_failure',
  },
  [ErrorStatus.ErrorResetPassword]: {
    message: 'error__reset_password',
  },
};

export const ErrorStatusDataAssets: AssetsProvider<ErrorStatus, ErrorStatusAssets> = {
  getAll() {
    return Object.keys(map).map(a => ({
      value: a as ErrorStatus,
      asset: this.getFor(a as ErrorStatus),
    }));
  },

  getFor(status: ErrorStatus) {
    return map[status];
  },
};
