import { TabEventEmitter, TabEventListener } from '../interfaces/TabEventEmitters';
import sEmitter from 'storage-emitter';
import { v4 as uuidv4 } from 'uuid';

export class TabEventEmitterImpl<T = void> implements TabEventEmitter<T> {
  constructor(private readonly _eventName: string) {
    sEmitter.on(_eventName, ({ tabId, payload }: { tabId: string; payload: T }) => {
      const isSelfHost = tabId === this._tabId;

      this._listeners.forEach(l => {
        if (!isSelfHost) l(payload);
      });
    });
  }

  /*
   * Unique id is used for determine if message origin is same tab
   * because sometime tab events may be handled by own listener and
   * that produces unexpected behavior.
   */
  private _tabId = uuidv4();
  private _listeners: TabEventListener<T>[] = [];

  triggerListeners(payload: T) {
    sEmitter.emit(this._eventName, { tabId: this._tabId, payload });
  }

  addListener(listener: TabEventListener<T>) {
    this._listeners.push(listener);
  }

  removeListener(listener: TabEventListener<T>) {
    const index = this._listeners.findIndex(l => l === listener);

    if (index !== -1) {
      this._listeners.splice(index, 1);
    }
  }
}
