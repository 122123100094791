import { NavigationGuardNext, Route, Location } from 'vue-router';
import { setDocumentTitle } from '@/router/helpers/setDocumentTitle';
import i18n from '@/plugins/i18n';
import { cloneRoute } from '@/router/helpers/cloneRoute';
import { saveToSessionStorage } from '@/modules/SessionStorage/saveToSessionStorage';
import { SessionStorageKeys } from '@/modules/SessionStorage/sessionStorageKeys';
import { EventBusType } from '@/modules/EventBus/EventBusType';
import { emitBusEvent } from '@/modules/EventBus/emitBusEvent';

const processRouteQuery = (route: Location) => {
  if (!route.query) {
    return;
  }

  const { token, email, sessionExpired } = route.query;

  if (token) {
    saveToSessionStorage(SessionStorageKeys.TOKEN, token as string);
    delete route.query.token;
  }

  if (email) {
    saveToSessionStorage(SessionStorageKeys.EMAIL, email as string);
    delete route.query.email;
  }

  if (sessionExpired) {
    setTimeout(() => emitBusEvent(EventBusType.SESSION_EXPIRED), 0);
    delete route.query.sessionExpired;
  }
};

export const beforeEach = (to: Route, from: Route, next: NavigationGuardNext) => {
  const pageLabel = to.meta && to.meta.label;
  const documentTitle = pageLabel ? `: ${i18n.t(pageLabel)}` : '';
  setDocumentTitle(`${window.APP_DATA.appName}${documentTitle}`);

  const { token, email, sessionExpired } = to.query;

  if (token || email || sessionExpired) {
    const nextRoute = cloneRoute(to);
    processRouteQuery(nextRoute);

    next(nextRoute);
  } else {
    next();
  }
};