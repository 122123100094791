import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { Locale } from '@/enumerations/Locale';
import { getLocaleFromLS } from '@/modules/LocaleStorage';

import enLocale from 'element-ui/lib/locale/lang/en';
import ruLocale from 'element-ui/lib/locale/lang/ru-RU';
import ukLocale from 'element-ui/lib/locale/lang/ua';

import enMessages from '@/locales/en.json';
import ruMessages from '@/locales/ru.json';
import ukMessages from '@/locales/uk.json';

const messages = {
  [Locale.En]: { ...enLocale, ...enMessages },
  [Locale.Ru]: { ...ruLocale, ...ruMessages },
  [Locale.Uk]: { ...ukLocale, ...ukMessages },
};

const LS_LOCALE = getLocaleFromLS();

Vue.use(VueI18n);

export const DEFAULT_LOCALE = (LS_LOCALE || Locale.Uk) as Locale;
export const DEFAULT_FALLBACK_LOCALE = (window.APP_DATA.language || Locale.En) as Locale;

export default new VueI18n({
  silentTranslationWarn: true,
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_FALLBACK_LOCALE,
  messages,
});
