import { RouteConfig } from 'vue-router';
import Login from '@/pages/Login/Index.vue';
import Registration from '@/pages/Registration/Index.vue';
import ResetPassword from '@/pages/ResetPassword/Index.vue';
import ChangePassword from '@/pages/ChangePassword/Index.vue';
import TwoStepVerification from '@/pages/TwoStepVerification/Index.vue';

export const routes: RouteConfig[] = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '*',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      label: 'route__login',
    },
  },
  {
    path: '/login/two-step-verification',
    name: '2fa',
    component: TwoStepVerification,
    meta: {
      label: 'route__2fa',
    },
  },
  {
    path: '/register',
    name: 'registration',
    component: Registration,
    meta: {
      label: 'route__registration',
    },
  },
  {
    path: '/reset',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      label: 'route__reset_password',
    },
  },
  {
    path: '/reset/:token',
    name: 'change-password',
    component: ChangePassword,
    meta: {
      label: 'route__change_password',
    },
  },
];
