import { Route, Location } from 'vue-router';
import cloneDeep from 'lodash/cloneDeep';

export const cloneRoute = (route: Route): Location => {
  const {
    path,
    name,
    params,
    query
  } = route;

  return {
    path,
    name: name || undefined,
    params: cloneDeep(params),
    query: cloneDeep(query)
  }
};