<template>
  <div class="footer">
    <ElRow class="footer__row">
      <ElCol>
        <img alt="" :src="logoSrc" />
        <div class="text__supplementary footer__service-description">
          {{ $t('service_description') }}
        </div>
      </ElCol>

      <ElCol class="footer__right">
        <a :href="`mailto: ${preferences.support_email}`">
          <div class="text__regular">{{ preferences.support_email || '' }}</div>
        </a>
      </ElCol>
    </ElRow>

    <ElRow class="footer__row">
      <ElCol class="footer__bottom" :span="24">
        <span class="text__supplementary text--placeholder footer__copyright">
          {{ $t('all_rights_reserved', { year: currentYear }) }}
        </span>
        <span class="footer__links">
          <a
            v-for="(link, index) in links"
            :key="index"
            :href="link.value"
            class="text__supplementary"
            >{{ $t(link.label) }}</a
          >
        </span>
      </ElCol>
    </ElRow>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import Logo from '@/assets/img/logo.svg';
import i18n from '@/plugins/i18n';
@Component
export default class Footer extends Vue {
  private get logoSrc() {
    return this.$getAppData().urls.org_logo || Logo;
  }

  private get preferences() {
    return this.$getAppData().preferences;
  }

  private get currentYear() {
    return new Date().getFullYear();
  }

  private get currentLocale() {
    return i18n.locale;
  }

  private get links() {
    return [
      {
        value: this.preferences.terms_url,
        label: 'terms_and_conditions',
      },
      {
        value: this.preferences.privacy_url,
        label: 'security_policy',
      },
      {
        value: `https://cascad.com/${this.currentLocale}/docs/`,
        label: 'documentation',
      },
    ].filter(({ value }) => !!value);
  }
}
</script>

<style lang="scss" scoped>
.footer {
  height: 164px;
  padding: 24px 60px 16px;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 0px #28253f;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    height: 24px;
    margin-bottom: 20px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__right {
    text-align: right;
    min-width: fit-content;
  }

  &__service-description,
  &__copyright {
    max-width: 218px;
  }

  &__copyright {
    min-width: fit-content;
  }

  &__links {
    margin-left: 77px;

    a:not(:last-child) {
      margin-right: 24px;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
  }
}
</style>
