import Vue from 'vue';
import getAppData from '@/modules/AppData';
import * as Sentry from '@sentry/vue';

export default function() {
  if (!!FRONT_SENTRY_DNS && !!FRONT_SENTRY_RELEASE) {
    Sentry.init({
      Vue: Vue,
      dsn: FRONT_SENTRY_DNS,
      release: FRONT_SENTRY_RELEASE,
      environment: getAppData().environment,
    });

    const tags = {
      theme: 'cascad',
      app: 'entrance',
      cell: getAppData().application_cell,
    };

    Sentry.setTags(tags);
  }
}
