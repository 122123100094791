<template>
  <div class="password-recovery">
    <h2 class="text__main-title text--bold append-dot mb-16">{{ $t('two_step_verification') }}</h2>
    <p
      v-show="backendError"
      class="text--centered text--error-message mt-0"
    >{{ $t('code_validation_failure') }}</p>
    <ElForm
      class="password-recovery__form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
      hide-required-asterisk
      @submit.prevent="submitForm"
    >
      <ElFormItem>
        <ElRow slot="label" class="login__password_label" type="flex" justify="space-between">
          <span>{{ $t('auth_code') }}</span>
        </ElRow>
        <ElInput
          id="authCode"
          :disabled="pageIsLoading"
          v-model="form.authCode"
          :placeholder="$t('auth_code')"
          autofocus
        />
      </ElFormItem>
      <ElFormItem>
        <ElRow type="flex" justify="center">
          <ElButton
            :loading="pageIsLoading"
            id="submit"
            type="primary"
            class="enter-button"
            native-type="submit"
            @click.prevent="submitForm"
          >{{ $t('verify') }}</ElButton>
        </ElRow>
      </ElFormItem>
    </ElForm>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
// Helpers
import entranceHttpService from '@/resource/EntranceHttpService';
import { rules } from './validationRules';
// Types
import { ElForm } from 'element-ui/types/form';

const INVALID_CODE = 'code_invalid';

@Component
export default class TwoStepVerification extends Vue {
  $refs!: {
    form: ElForm;
  };

  private form = {
    authCode: '',
  };

  private rules = rules;

  private pageIsLoading = false;
  private backendError = false;

  private submitForm() {
    this.$refs.form.validate(valid => {
      if (!valid) {
        return;
      } else {
        this.validateCode();
      }
    });
  }

  private async validateCode() {
    const { authCode } = this.form;
    const formData = new FormData();
    formData.append('auth_code', authCode);
    this.pageIsLoading = true;

    const response = await entranceHttpService.loginWith2FA(formData);
    if (response.status === 200 && response.data && response.data.success) {
      location.href = response.data.redirectTo || '/';
    }

    if (
      response.status !== 200 ||
      (response.status === 200 && response.data.error === INVALID_CODE)
    ) {
      this.backendError = true;
    }

    this.pageIsLoading = false;
  }
}
</script>

<style lang="scss" scoped>
.password-recovery {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__form {
    min-width: 340px;
  }
}
</style>
