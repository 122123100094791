const ErrorStatus = {
  ServerFailure: 'server_failure' as const,
  EmailNotConfirmed: 'email_not_confirmed' as const,
  InvalidCredentials: 'invalid_credentials' as const,
  UserAlreadyExists: 'user_already_exists' as const,
  TokenExpired: 'token_expired' as const,
  TokenNotFit: 'token_not_fit' as const,
  Default: 'default' as const,
  ErrorResetPassword: 'error_reset_password' as const,
};

type ErrorStatus = typeof ErrorStatus[keyof typeof ErrorStatus];

export { ErrorStatus };
