import { HttpService } from '@/resource/HttpService';
import { BaseAxiosInstance } from '@/resource/instance';

class EntranceHttpService extends HttpService {
  constructor() {
    super(BaseAxiosInstance);
  }

  async login(data: { email: string; password: string; 'g-recaptcha-response': string | null }) {
    return await this.post<{
      need2FA: boolean;
      success: boolean;
      redirectTo?: string;
    }>('/login_check', data, {
      /* For not rejecting promise on error code */
      validateStatus(status) {
        return status >= 200;
      },
    });
  }
  async loginWith2FA(formData: FormData) {
    return await this.post<{
      success?: boolean;
      redirectTo?: string;
      need_2fa?: boolean;
      error?: string;
    }>('/2fa_check', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      validateStatus(status) {
        return status >= 200;
      },
    });
  }

  async register(data: {
    email: string;
    password: string;
    confirm_password: string;
    'g-recaptcha-response': string | null;
    token: string;
  }) {
    return await this.post('/registration', data, {
      validateStatus(status) {
        return status >= 200;
      },
    });
  }
  async resetPassword(data: { email: string; 'g-recaptcha-response': string | null }) {
    return await this.post('/resetting', data, {
      validateStatus(status) {
        return status >= 200;
      },
    });
  }

  async getUserByToken(token: string) {
    return await this.get(`/resetting/${token}`, {
      validateStatus(status) {
        return status >= 200;
      },
    });
  }

  async changePassword(data: { token: string; password: string; confirm_password: string }) {
    return await this.post('/resetting/password', data, {
      validateStatus(status) {
        return status >= 200;
      },
    });
  }
}

export default new EntranceHttpService();
