<template>
  <div :class="{ 'success-message': emailIsSent }" class="login">
    <div v-if="emailIsSent">
      <RegisteredByToken v-if="form.token" />
      <RegisteredWithoutToken v-else :email="form.email" />
    </div>
    <div v-else>
      <h2 class="text__main-title text--bold append-dot text--centered">
        {{ $t('registration_title') }}
      </h2>
      <ElRow class="login__form">
        <ElForm
          ref="form"
          v-on:validate="toggleTooltipVisibility"
          :model="form"
          :rules="rules"
          label-position="top"
          hide-required-asterisk
          @submit.native.prevent="submitForm"
        >
          <ElFormItem prop="email" label="Email">
            <ElInput
              id="email"
              v-model="form.email"
              :placeholder="$t('email_placeholder')"
              :disabled="emailFromQuery"
            />
          </ElFormItem>

          <ElFormItem prop="password">
            <ElRow slot="label" class="login__password_label" type="flex" justify="space-between">
              <span>{{ $t('password_label') }}</span>
            </ElRow>
            <PasswordTooltip :isVisible.sync="toolTipIsVisible">
              <ElInput
                id="password"
                v-model="form.password"
                :placeholder="$t('password_placeholder')"
                type="password"
                show-password
                @keyup.native.enter="submitForm()"
              />
            </PasswordTooltip>
          </ElFormItem>

          <ElFormItem class="mb-24" prop="confirmPassword">
            <ElRow slot="label" class="login__password_label" type="flex" justify="space-between">
              <span>{{ $t('confirm_password_label') }}</span>
            </ElRow>
            <ElInput
              id="passwordConfirm"
              v-model="form.confirmPassword"
              :placeholder="$t('password_placeholder')"
              show-password
              @keyup.native.enter="submitForm()"
            />
          </ElFormItem>

          <p class="text__regular mb-32">
            {{ $t('agreement_text') }}
            <RouterLink to="/terms-and-conditions">
              {{ $t('terms_and_conditions_register') }}
            </RouterLink>
            {{ $t('and_prefix') }}
            <RouterLink to="/security-policy">{{ $t('terms_and_conditions_register') }}</RouterLink>
          </p>

          <ElFormItem>
            <ElRow type="flex" justify="center">
              <ElButton
                id="submit"
                :loading="loading"
                type="primary"
                native-type="submit"
                class="enter-button"
              >
                {{ $t('sign_up') }}
              </ElButton>
            </ElRow>
          </ElFormItem>
          <p class="text__regular text--centered">
            {{ $t('account_exist') }}
            <RouterLink to="/login">{{ $t('sign_in') }}</RouterLink>
          </p>
          <VueRecaptcha
            v-if="recaptchaData.enabled && recaptchaData.key"
            ref="recaptcha"
            :sitekey="recaptchaData.key"
            size="invisible"
            loadRecaptchaScript
            @verify="registerUser"
            @expired="submitForm"
          ></VueRecaptcha>
        </ElForm>
      </ElRow>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import VueRecaptcha from 'vue-recaptcha';
import PasswordTooltip from './PasswordTooltip.vue';
// Helpers
import entranceHttpService from '@/resource/EntranceHttpService';
import i18n from '@/plugins/i18n';
import { SessionStorageKeys } from '@/modules/SessionStorage/sessionStorageKeys';
// Types
import { ElForm } from 'element-ui/types/form';
import { hasSpecialCharachterRegExp } from '@/config/regexps/hasSpecialCharachterRegExp';
import { hasUpperAndLoweCaseLettersRegExp } from '@/config/regexps/hasUpperAndLoweCaseLettersRegExp';
import { hasNoSpacesRegExp } from '@/config/regexps/hasNoSpacesRegExp';
import { hasNumbersRegExp } from '@/config/regexps/hasNumbersRegExp';
import RegisteredByToken from './components/RegisteredByToken.vue';
import RegisteredWithoutToken from './components/RegisteredWithoutToken.vue';
import RecaptchaType from 'vue-recaptcha/types';
import { ErrorStatus } from '@/helpers/errorProvider/ErrorStatus';
import { ErrorStatusDataAssets } from '@/helpers/errorProvider/ErrorStatusDataAssets';

const ErrorToken = {
  TOKEN_NOT_EXIST: 'Token does not exist or expired',
  EMAIL_NOT_FIT: 'This email not fit for this token',
};

@Component({
  components: {
    RegisteredWithoutToken,
    RegisteredByToken,
    VueRecaptcha,
    PasswordTooltip,
  },
})
export default class Registration extends Vue {
  $refs!: {
    form: ElForm;
    recaptcha: RecaptchaType;
  };

  private created() {
    this.form.email = this.$session.get(SessionStorageKeys.EMAIL) || '';
    this.form.token = this.$session.get(SessionStorageKeys.TOKEN) || '';
    const regByToken = this.$getAppData().preferences.registration_by_token;
    if (regByToken && !this.form.token) {
      this.$router.push('/login');
    }
  }

  private loading = false;
  private emailIsSent = false;

  private form = {
    email: '',
    password: '',
    confirmPassword: '',
    gRecaptchResponse: '',
    token: '',
  };

  private toolTipIsVisible = false;

  private recaptchaData = this.$getAppData().recaptcha;

  // Validation rules
  private validatePassIsEqual = (rule: any, value: string, callback: Function) =>
    value !== this.form.password ? callback(new Error()) : callback();

  private hasNumbers = (rule: any, value: string, callback: Function) =>
    !value.match(hasNumbersRegExp) ? callback(new Error()) : callback();

  private hasSpecialCharachter = (rule: any, value: string, callback: Function) =>
    !value.match(hasSpecialCharachterRegExp) ? callback(new Error()) : callback();

  private hasNoSpaces = (rule: any, value: string, callback: Function) =>
    !value.match(hasNoSpacesRegExp) ? callback(new Error()) : callback();

  private hasUpperAndLoweCaseLetters = (rule: any, value: string, callback: Function) =>
    !value.match(hasUpperAndLoweCaseLettersRegExp) ? callback(new Error()) : callback();

  private get emailFromQuery() {
    return !!this.$session.get(SessionStorageKeys.EMAIL);
  }

  private rules = {
    email: [
      {
        required: true,
        message: i18n.t('validation_email_require'),
        trigger: 'blur',
      },
      {
        type: 'email',
        required: true,
        message: i18n.t('validation_email'),
        trigger: 'blur',
      },
    ],
    password: [
      {
        required: true,
        message: i18n.t('validation_password'),
        trigger: 'blur',
      },
      {
        range: true,
        min: 9,
        message: i18n.t('validation_password_length', { n: 9 }),
        trigger: 'blur',
      },
      {
        validator: this.hasNumbers,
        message: i18n.t('validation_one_digit'),
        trigger: 'blur',
      },
      {
        validator: this.hasSpecialCharachter,
        message: i18n.t('validation_special_charachters'),
        trigger: 'blur',
      },
      {
        validator: this.hasNoSpaces,
        message: i18n.t('validation_no_spaces'),
        trigger: 'blur',
      },
      {
        validator: this.hasUpperAndLoweCaseLetters,
        message: i18n.t('validation__lower_and_uppercase_letters'),
        trigger: 'blur',
      },
    ],
    confirmPassword: [
      {
        validator: this.validatePassIsEqual,
        message: i18n.t('passwords_must_match'),
        trigger: 'blur',
      },
    ],
  };

  private async registerUser(reCaptchaKey: string | null) {
    const { email, password, confirmPassword, token } = this.form;
    const response = await entranceHttpService.register({
      email,
      password,
      confirm_password: confirmPassword,
      'g-recaptcha-response': reCaptchaKey || null,
      token,
    });

    if (response.status === 200) {
      this.emailIsSent = true;
      this.loading = false;
      return;
    }

    let errorCode = null;

    if (response.status === 500) {
      errorCode = ErrorStatus.ServerFailure;
    }

    if (response.data && response.data.errors) {
      if (response.status === 422) {
        if (response.data.errors.email && /^User.*exists$/.test(response.data.errors.email)) {
          errorCode = ErrorStatus.UserAlreadyExists;
        } else if (response.data.errors.token === ErrorToken.TOKEN_NOT_EXIST) {
          errorCode = ErrorStatus.TokenExpired;
        } else if (response.data.errors.token === ErrorToken.EMAIL_NOT_FIT) {
          errorCode = ErrorStatus.TokenNotFit;
        }
      }

      if (!errorCode) {
        errorCode = ErrorStatus.Default;
      }

      const message = this.$t(ErrorStatusDataAssets.getFor(errorCode as ErrorStatus).message);

      this.$message({ message: message as string, type: 'error', showClose: true, duration: 5000 });

      if (this.$refs.recaptcha !== undefined) {
        this.$refs.recaptcha.reset();
      }
      this.loading = false;
    }
  }

  private submitForm() {
    this.$refs.form.validate((valid: boolean) => {
      if (!valid) {
        return;
      }
      this.loading = true;
      if (this.recaptchaData.enabled && this.recaptchaData.key) {
        this.$refs.recaptcha.execute();
      } else {
        this.registerUser(null);
      }
    });
  }

  private toggleTooltipVisibility(propName: string, isValid: boolean) {
    if (propName === 'password') {
      this.toolTipIsVisible = !isValid;
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.success-message {
    justify-content: center;
    margin-top: 0;
    height: calc(100vh - 96px - 164px); // 100vh - (header+footer)
    max-width: 500px;
  }

  &__password_label {
    width: 100%;
  }

  &__form {
    margin-top: 32px;
    width: 320px;
  }
}
</style>
