import { render, staticRenderFns } from "./RegisteredByToken.vue?vue&type=template&id=79bd76fe&scoped=true"
import script from "./RegisteredByToken.vue?vue&type=script&lang=ts"
export * from "./RegisteredByToken.vue?vue&type=script&lang=ts"
import style0 from "./RegisteredByToken.vue?vue&type=style&index=0&id=79bd76fe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79bd76fe",
  null
  
)

export default component.exports