import Vue from 'vue';
import App from './App.vue';
import router from './router';

import i18n from './plugins/i18n';
import './plugins/element';
import './plugins/session';
import './plugins/appData';
import './plugins/eventBus';
import sentry from './plugins/sentry';

import './assets/styles/main.scss';
import { tabEventEmittersCollection } from './modules/events/services/tabEventEmittersCollection';

sentry();

Vue.config.productionTip = false;

tabEventEmittersCollection.loginPageInitiated.triggerListeners();
tabEventEmittersCollection.dashboardInitiated.addListener(() => window.location.reload());

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');
