<template>
  <div v-bind:class="{ 'success-message': emailIsSent }" class="password-recovery">
    <div v-if="emailIsSent">
      <h2 class="text__main-title text--centered text--bold append-dot password-recovery__title">
        {{ $t('reset_password__title') }}
      </h2>
      <p class="text--centered password-recovery__msg">
        {{ $t('password_recover_instruction', { email: form.email }) }}
      </p>
    </div>
    <ElRow v-else>
      <h2 class="text__main-title text--bold append-dot mb-16">{{ $t('password_recover') }}</h2>
      <p class="text--centered mt-0">{{ $t('password_recover_send_to_email') }}</p>

      <ElForm
        ref="form"
        :model="form"
        :rules="rules"
        class="password-recovery__form"
        label-position="top"
        hide-required-asterisk
        @submit.native.prevent="submitForm('form')"
      >
        <ElFormItem prop="email" label="Email">
          <ElInput
            id="email"
            v-model="form.email"
            :disabled="isLoading"
            :placeholder="$t('email_placeholder')"
          />
        </ElFormItem>

        <ElFormItem class="password-recovery__form-item">
          <ElRow type="flex" justify="center">
            <ElButton
              id="submit"
              :loading="isLoading"
              type="primary"
              class="enter-button"
              native-type="submit"
            >
              {{ $t('password_recover') }}
            </ElButton>
            <VueRecaptcha
              v-if="recaptchaData.enabled && recaptchaData.key"
              ref="recaptcha"
              :sitekey="recaptchaData.key"
              size="invisible"
              loadRecaptchaScript
              @verify="resetPassword"
              @expired="submitForm"
            />
          </ElRow>
        </ElFormItem>
      </ElForm>
    </ElRow>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
// Helpers
import entranceHttpService from '@/resource/EntranceHttpService';
// Data
import { rules } from './validationRules';
// Types
import { ElForm } from 'element-ui/types/form';
import VueRecaptcha from 'vue-recaptcha';
import RecaptchaType from 'vue-recaptcha/types';
import { ErrorStatus } from '@/helpers/errorProvider/ErrorStatus';
import { ErrorStatusDataAssets } from '@/helpers/errorProvider/ErrorStatusDataAssets';

@Component({
  components: {
    VueRecaptcha,
  },
})
export default class ResetPassword extends Vue {
  $refs!: {
    form: ElForm;
    recaptcha: RecaptchaType;
  };

  private form = {
    email: '',
  };

  private isLoading = false;
  private emailIsSent = false;

  private rules = rules;
  private recaptchaData = this.$getAppData().recaptcha;

  private async resetPassword(reCaptchaKey: string | null) {
    const { email } = this.form;
    const response = await entranceHttpService.resetPassword({
      email,
      'g-recaptcha-response': reCaptchaKey || null,
    });

    if (response.status >= 400) {
      this.handleError();
    }

    this.isLoading = false;
    this.emailIsSent = true;
  }

  private submitForm() {
    this.$refs.form.validate(valid => {
      if (!valid) {
        return;
      }
      this.isLoading = true;
      if (this.recaptchaData.enabled && this.recaptchaData.key) {
        this.$refs.recaptcha.execute();
      } else {
        this.resetPassword(null);
      }
    });
  }

  private handleError() {
    this.$message({
      message: this.$t(
        ErrorStatusDataAssets.getFor(ErrorStatus.ErrorResetPassword).message
      ) as string,
      type: 'error',
      showClose: true,
      duration: 5000,
    });
  }
}
</script>

<style lang="scss" scoped>
.password-recovery {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 340px;

  &__title {
    display: flex;
    justify-content: center;
  }

  &__msg {
    word-break: break-word;
  }

  &.success-message {
    justify-content: center;
    margin-top: 0;
    height: calc(100vh - 96px - 164px); // 100vh - (header+footer)
    max-width: 500px;
  }

  &__form {
    width: 100%;

    &-item {
      margin-bottom: 0px;
    }
  }
}
</style>
