import i18n from '@/plugins/i18n';

export const rules = {
  authCode: [
    {
      required: true,
      message: i18n.t('validation_key'),
      trigger: 'blur',
    },
  ],
};
