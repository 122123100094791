<template>
  <div class="languages">
    <div
      class="languages__lang"
      :class="{ active: currentLocale === lang.locale }"
      v-for="(lang, index) in langs"
      :key="index"
      @click="switchLocale(lang.locale)"
    >
      {{ $t(lang.translation) }}
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Locale } from '@/enumerations/Locale';
import i18n from '@/plugins/i18n';
import { setLocaleToLS } from '@/modules/LocaleStorage';

@Component
export default class LanguageToggle extends Vue {
  private langs = {
    [Locale.Ru]: {
      translation: 'russian',
      locale: Locale.Ru,
    },
    [Locale.Uk]: {
      translation: 'ukrainian',
      locale: Locale.Uk,
    },
    [Locale.En]: {
      translation: 'english',
      locale: Locale.En,
    },
  };

  private get currentLocale() {
    return i18n.locale;
  }

  private switchLocale(locale: Locale) {
    i18n.locale = locale;
    setLocaleToLS(locale);
  }
}
</script>

<style scoped lang="scss">
@import '../assets/styles/variables';
.languages {
  display: flex;
  margin-right: 30px;

  &__lang {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 15px;
    }

    &.active {
      color: $--color-white-48;
    }
  }
}
</style>
