<template>
  <div>
    <h2 class="text__main-title text--centered text--bold append-dot">
      {{ $t('letter_is_sent') }}
    </h2>
    <p class="text--centered">{{ $t('email_confirmation', { email }) }}</p>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class RegisteredWithoutToken extends Vue {
  @Prop({ type: String, required: true })
  private readonly email!: string;
}
</script>
