<template>
  <ElRow class="header" type="flex" justify="space-between" align="middle">
    <ElCol>
      <img :src="logoSrc" class="header__logo" />
    </ElCol>

    <ElCol>
      <ElRow type="flex" justify="end">
        <LanguageToggle />
        <ElButton plain :disabled="isAlreadyOnLogin" @click="handleLoginClick">{{
          $t('login')
        }}</ElButton>
      </ElRow>
    </ElCol>
  </ElRow>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import Logo from '@/assets/img/logo.svg';
import LanguageToggle from '@/components/LanguageToggle.vue';

@Component({
  components: { LanguageToggle },
})
export default class Header extends Vue {
  private get logoSrc() {
    return this.$getAppData().urls.org_logo || Logo;
  }

  private get isAlreadyOnLogin() {
    return this.$route.name === 'login';
  }

  private handleLoginClick() {
    this.$router.push({ name: 'login' });
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: 96px;
  padding: 0 60px;

  &__logo {
    height: 32px;
  }
}
</style>
