import Vue from 'vue';
import {
  Button,
  Input,
  Form,
  FormItem,
  Row,
  Col,
  Loading,
  Message,
  Checkbox,
  Tooltip,
} from 'element-ui';
import '@/assets/styles/elementVariables.scss';

Vue.use(Button);
Vue.use(Input);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Loading.directive);
Vue.use(Checkbox);
Vue.use(Tooltip);
Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading.service;
