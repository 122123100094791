<template>
  <div class="login">
    <div class="text__main-title text--bold append-dot">{{ $t('login_title') }}</div>

    <ElRow class="login__form">
      <ElForm
        ref="form"
        :model="form"
        :rules="rules"
        label-position="top"
        hide-required-asterisk
        @submit.native.prevent="onSubmit"
      >
        <ElFormItem prop="email" label="Email">
          <ElInput
            id="email"
            v-model="form.email"
            :disabled="isLoading"
            :placeholder="$t('email_placeholder')"
          />
        </ElFormItem>

        <ElFormItem prop="password">
          <ElRow slot="label" class="login__password_label" type="flex" justify="space-between">
            <span>{{ $t('password_label') }}</span>
            <RouterLink id="forgot" :to="{ name: 'reset-password' }" class="forgot-password">
              {{ $t('forgot') }}
            </RouterLink>
          </ElRow>

          <ElInput
            id="password"
            v-model="form.password"
            :disabled="isLoading"
            :placeholder="$t('password_placeholder')"
            type="password"
            show-password
            @keyup.native.enter="onSubmit"
          />
        </ElFormItem>

        <ElFormItem class="form-item">
          <ElRow type="flex" justify="center">
            <ElButton
              id="submit"
              :loading="isLoading"
              type="primary"
              class="enter-button"
              native-type="submit"
            >
              {{ $t('sign_in') }}
            </ElButton>

            <VueRecaptcha
              v-if="recaptchaData.enabled && recaptchaData.key"
              ref="recaptcha"
              :sitekey="recaptchaData.key"
              size="invisible"
              loadRecaptchaScript
              @verify="loginProcess"
              @expired="onSubmit"
            ></VueRecaptcha>
          </ElRow>
        </ElFormItem>
      </ElForm>
    </ElRow>

    <ElRow
      v-if="isRegistrationAllowed"
      type="flex"
      justify="center"
      class="login__registration_link"
    >
      <span>{{ $t('dont_have_an_account') }}</span>
      <RouterLink :to="{ name: 'registration' }">{{ $t('registration') }}</RouterLink>
    </ElRow>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { rules } from './validationRules';
import entranceHttpService from '@/resource/EntranceHttpService';
import { ElForm } from 'element-ui/types/form';
import { AxiosResponse } from 'axios';
import { SessionStorageKeys } from '@/modules/SessionStorage/sessionStorageKeys';
import VueRecaptcha from 'vue-recaptcha';
import RecaptchaType from 'vue-recaptcha/types';
import { ErrorStatus } from '@/helpers/errorProvider/ErrorStatus';
import { ErrorStatusDataAssets } from '@/helpers/errorProvider/ErrorStatusDataAssets';
@Component({ components: { VueRecaptcha } })
export default class Login extends Vue {
  $refs!: {
    form: ElForm;
    recaptcha: RecaptchaType;
  };

  private created() {
    const preservedEmail =
      localStorage.getItem('userEmail') || this.$session.get(SessionStorageKeys.EMAIL);
    if (preservedEmail) {
      this.form.email = preservedEmail;
      localStorage.removeItem('userEmail');
    }

    const preservedPassword = localStorage.getItem('password');
    if (preservedPassword) {
      this.form.password = preservedPassword;
      localStorage.removeItem('password');
    }
  }

  private isLoading = false;
  private rules = rules;

  private form = {
    email: '',
    password: '',
  };

  private recaptchaData = this.$getAppData().recaptcha;

  private get isRegistrationAllowed() {
    return !this.$getAppData().preferences.registration_by_token;
  }

  private async onSubmit() {
    this.$refs.form.validate(async valid => {
      if (!valid) {
        return;
      }
      if (this.recaptchaData.enabled && this.recaptchaData.key) {
        this.$refs.recaptcha.execute();
      } else {
        await this.loginProcess(null);
      }
    });
  }

  private async loginProcess(reCaptchaKey: string | null) {
    this.isLoading = true;

    const { email, password } = this.form;

    const resp = await entranceHttpService.login({
      email,
      password,
      'g-recaptcha-response': reCaptchaKey || null,
    });

    if (resp.data.need2FA) {
      this.$router.push({ name: '2fa' });
      return;
    }

    if (resp.data.success) {
      this.processLoginSuccess(resp.data);
    } else {
      this.processLoginError(resp);
    }

    if (this.$refs.recaptcha !== undefined) {
      this.$refs.recaptcha.reset();
    }

    this.isLoading = false;
  }

  private processLoginSuccess(data: { redirectTo?: string }) {
    let redirect = '/';

    if (data.redirectTo) {
      redirect = data.redirectTo;
    }

    location.href = redirect;
  }

  private processLoginError(resp: AxiosResponse) {
    let errorCode = null;

    if (resp.status === 500) {
      errorCode = ErrorStatus.ServerFailure;
    } else {
      if (resp.data.message) {
        switch (resp.data.message) {
          case 'Email not confirmed':
            errorCode = ErrorStatus.EmailNotConfirmed;
            break;
          case 'Credentials not correct':
            errorCode = ErrorStatus.InvalidCredentials;
            break;
          default:
            errorCode = ErrorStatus.Default;
        }
      }
    }

    if (!errorCode) {
      errorCode = ErrorStatus.Default;
    }

    const message = this.$t(ErrorStatusDataAssets.getFor(errorCode as ErrorStatus).message);

    this.$message({ message: message as string, type: 'error', showClose: true, duration: 5000 });
  }
}
</script>

<style lang="scss">
.login {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 350px;

  .form-item {
    margin-bottom: 0px;
  }

  &__password_label {
    width: 100%;
  }

  &__form {
    margin-top: 32px;
    max-width: 320px;
    width: 100%;
  }

  &__registration_link {
    margin-top: 32px;

    a {
      margin-left: 5px;
    }
  }
}
</style>
